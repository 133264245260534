// import request from "@/utils/request";
import router from "./router";
import { getMenu } from "@/api/Home";
import Store from "@/store";
let getRouter;
// 路由拦截router.beforeEach
const routerListBak = [
  { url: "/map", name: "Map", title: "导航地图", hidden: true, require: true },
  {
    url: "/notice",
    name: "Notice",
    title: "公告",
    require: true,
    hidden: true,
    children: [
      {
        url: "/noticeDetail",
        name: "NoticeDetail",
        title: "公告详情",
      },
    ],
  },
  { url: "/introduce", name: "Introduce", title: "展会概况" },
  {
    url: "/showinfo",
    name: "ShowInfo",
    title: "展商展品",
    children: [
      {
        url: "/showMerchantDetail",
        name: "ShowMerchantDetail",
        title: "展商详情",
      },
      { url: "/showStoreDetail", name: "ShowStoreDetail", title: "展品详情" },
    ],
  },
  {
    url: "/news",
    name: "News",
    title: "新闻资讯",
    children: [
      {
        url: "/newsDetail",
        name: "NewsDetail",
        title: "新闻详情",
      },
    ],
  },
  {
    url: "/meeting",
    name: "Meeting",
    title: "会议论坛",
    children: [
      {
        url: "/meetingDetail",
        name: "MeetingDetail",
        title: "会议详情",
      },
    ],
  },
  { url: "/service", name: "Service", title: "服务中心" },
  { url: "/about", name: "About", title: "关于我们" },
];
routerListBak;
const routerList = [
  { url: "/map", name: "Map", title: "导航地图", hidden: true, require: true },
  {
    url: "/notice",
    name: "Notice",
    title: "公告",
    require: true,
    hidden: true,
    children: [
      {
        url: "/noticeDetail",
        name: "NoticeDetail",
        title: "公告详情",
      },
    ],
  },
  {
    url: "/introduceAll",
    name: "IntroduceAll",
    title: "关于鲁台会",
    showChildren: true,
    children: [
      {
        url: "/introduce",
        name: "Introduce",
        title: "鲁台会概况",
      },
      {
        url: "/lookingBack",
        name: "LookingBack",
        title: "精彩回眸",
      },
      {
        url: "/historicalReview",
        name: "HistoricalReview",
        title: "历届回顾",
      },
      {
        url: "/introduceDetail",
        name: "IntroduceDetail",
        title: "文章详情",
        hidden: true,
        component: () => import("@/views/IntroduceAll/IntroduceDetail.vue"),
      },
    ],
  },
  {
    url: "/meeting",
    name: "Meeting",
    title: "主体活动",
    children: [
      {
        url: "/meetingDetailByNews",
        name: "MeetingDetailByNews",
        title: "活动详情",
        component: () =>
          import("@/views/MeetingDetail/MeetingDetailByNews.vue"),
      },
      {
        url: "/meetingDetail",
        name: "MeetingDetail",
        title: "活动详情",
      },
    ],
  },
  {
    url: "/specialActivity",
    name: "SpecialActivity",
    title: "专项活动 ",
    showChildren: true,
    redirect: { name: "SpecialActivityAll" },
    children: [
      {
        url: "/specialActivity1",
        name: "SpecialActivity1",
        title: "第15届海峡两岸风筝文化交流活动",
        component: () => import("@/views/SpecialActivity/index.vue"),
      },
      {
        url: "/specialActivity2",
        name: "SpecialActivity2",
        title: "制造赋能 兴在齐鲁",
        component: () => import("@/views/SpecialActivity/index.vue"),
      },
      {
        url: "/specialActivity3",
        name: "SpecialActivity3",
        title: "科技赋能 赢在齐鲁",
        component: () => import("@/views/SpecialActivity/index.vue"),
      },
      {
        url: "/specialActivity4",
        name: "SpecialActivity4",
        title: "健康赋能 享在齐鲁",
        component: () => import("@/views/SpecialActivity/index.vue"),
      },
      {
        url: "/specialActivityAll",
        name: "SpecialActivityAll",
        title: "全部",
        hidden: true,
        component: () => import("@/views/SpecialActivity/index.vue"),
      },
      {
        url: "/specialActivityDetail",
        name: "SpecialActivityDetail",
        title: "活动详情",
        hidden: true,
        component: () =>
          import("@/views/SpecialActivity/specialActivityDetail.vue"),
      },
    ],
  },
  {
    url: "/investmentTalent",
    name: "InvestmentTalent",
    title: "双招双引",
    showChildren: true,
    redirect: { name: "InvestmentTalentAll" },
    children: [
      {
        url: "/investment",
        name: "Investment",
        title: "招商引资",
        component: () => import("@/views/InvestmentTalent"),
      },
      {
        url: "/talent",
        name: "Talent",
        title: "招才引智",
        component: () => import("@/views/InvestmentTalent"),
      },
      {
        url: "/investmentTalentAll",
        name: "InvestmentTalentAll",
        title: "全部",
        hidden: true,
        component: () => import("@/views/InvestmentTalent"),
      },
      {
        url: "/investmentTalentDetail",
        name: "InvestmentTalentDetail",
        title: "文章详情",
        hidden: true,
        component: () =>
          import("@/views/InvestmentTalent/InvestmentTalentDetail"),
      },
    ],
  },
  {
    url: "/news",
    name: "News",
    title: "新闻中心",
    children: [
      {
        url: "/newsDetail",
        name: "NewsDetail",
        title: "新闻详情",
      },
    ],
  },
  // { url: "/service", name: "Service", title: "服务中心" },
  // { url: "/about", name: "About", title: "关于我们" },
  {
    url: "/showinfo",
    name: "ShowInfo",
    title: "在线展厅",
    children: [
      {
        url: "/showMerchantDetail",
        name: "ShowMerchantDetail",
        title: "展商详情",
      },
      { url: "/showStoreDetail", name: "ShowStoreDetail", title: "展品详情" },
    ],
  },
  // {
  //   url: "/service",
  //   name: "Service",
  //   title: "参会服务",
  //   children: [
  //     {
  //       url: "/serviceDetail",
  //       name: "ServiceDetail",
  //       title: "服务详情",
  //       hidden: true,
  //       component: () => import("@/views/Service/serviceDetail.vue"),
  //     },
  //   ],
  // },
  {
    url: "/play",
    name: "Play",
    title: "乐游潍坊",
    children: [
      {
        url: "/weifangIntro",
        name: "WeifangIntro",
        title: "潍坊概况",
        hidden: true,
        component: () => import("@/views/Play/WeifangIntro.vue"),
      },
      {
        url: "/boutiqueAttractions",
        name: "BoutiqueAttractions",
        title: "精品旅游景点",
        hidden: true,
        component: () => import("@/views/Play/BoutiqueAttractions.vue"),
      },
      {
        url: "/attractionsDetail",
        name: "AttractionsDetail",
        title: "景点详情",
        hidden: true,
        component: () => import("@/views/Play/AttractionsDetail.vue"),
      },
      {
        url: "/specialtyCatering",
        name: "SpecialtyCatering",
        title: "特色餐饮",
        hidden: true,
        component: () => import("@/views/Play/BoutiqueAttractions.vue"),
      },
      {
        url: "/cateringDetail",
        name: "CateringDetail",
        title: "餐饮详情",
        hidden: true,
        component: () => import("@/views/Play/AttractionsDetail.vue"),
      },
      {
        url: "/giftsList",
        name: "GiftsList",
        title: "伴手礼/土特产",
        hidden: true,
        component: () => import("@/views/Play/BoutiqueAttractions.vue"),
      },
      {
        url: "/giftsDetail",
        name: "GiftsDetail",
        title: "伴手礼/土特产",
        hidden: true,
        component: () => import("@/views/Play/AttractionsDetail.vue"),
      },
      {
        url: "/intangibleCulturalHeritage",
        name: "IntangibleCulturalHeritage",
        title: "非遗项目",
        hidden: true,
        component: () => import("@/views/Play/BoutiqueAttractions.vue"),
      },
      {
        url: "/intangibleCulturalHeritageDetail",
        name: "IntangibleCulturalHeritageDetail",
        title: "非遗项目",
        hidden: true,
        component: () => import("@/views/Play/AttractionsDetail.vue"),
      },
      {
        url: "/traffic",
        name: "Traffic",
        title: "交通概况",
        hidden: true,
        component: () => import("@/views/Play/BoutiqueAttractions.vue"),
      },
      {
        url: "/trafficDetail",
        name: "TrafficDetail",
        title: "交通概况",
        hidden: true,
        component: () => import("@/views/Play/AttractionsDetail.vue"),
      },
    ],
  },
  {
    url: "/choice",
    name: "Choice",
    title: "选择山东",
    children: [
      {
        url: "/advantageDetail",
        name: "AdvantageDetail",
        title: "山东优势",
        hidden: true,
        component: () => import("@/views/Choice/AdvantageDetail.vue"),
      },
      {
        url: "/weiFangIndex",
        name: "WeiFangIndex",
        title: "城市推介",
        hidden: true,
        component: () => import("@/views/Choice/WeiFangIndex.vue"),
        children: [
          {
            url: "/supportivePolicy",
            name: "SupportivePolicy",
            title: "扶持政策",
            hidden: true,
            component: () => import("@/views/Choice/SupportivePolicy.vue"),
          },
          {
            url: "/supportivePolicyDetail",
            name: "SupportivePolicylDetail",
            title: "扶持政策",
            hidden: true,
            component: () => import("@/views/Choice/IntroductionDetail.vue"),
          },
          {
            url: "/keyIndustries",
            name: "KeyIndustries",
            title: "重点产业",
            hidden: true,
            component: () => import("@/views/Choice/KeyIndustries.vue"),
          },
          {
            url: "/keyIndustriesDetail",
            name: "KeyIndustriesDetail",
            title: "重点产业",
            hidden: true,
            component: () => import("@/views/Choice/IntroductionDetail.vue"),
          },
        ],
      },
      {
        url: "/businessInformation",
        name: "BusinessInformation",
        title: "商务资讯",
        hidden: true,
        component: () => import("@/views/Choice/BusinessInformation.vue"),
      },
      {
        url: "/businessDetail",
        name: "BusinessDetail",
        title: "商务资讯",
        hidden: true,
        component: () => import("@/views/Choice/BusinessDetail.vue"),
      },
      {
        url: "/opportunityList",
        name: "OpportunityList",
        title: "合作机遇",
        hidden: true,
        component: () => import("@/views/Choice/OpportunityList.vue"),
      },
      {
        url: "/opportunityDetail",
        name: "OpportunityDetail",
        title: "合作机遇",
        hidden: true,
        component: () => import("@/views/Choice/OpportunityDetail.vue"),
      },
      {
        url: "/professionalTalents",
        name: "ProfessionalTalents",
        title: "专业人才",
        hidden: true,
        component: () => import("@/views/Choice/ProfessionalTalents.vue"),
      },
      {
        url: "/talentsDetail",
        name: "TalentsDetail",
        title: "专业人才",
        hidden: true,
        component: () => import("@/views/Choice/TalentsDetail.vue"),
      },
      {
        url: "/importantIndustry",
        name: "ImportantIndustry",
        title: "重点产业",
        hidden: true,
        component: () => import("@/views/Choice/ImportantIndustry.vue"),
      },
      {
        url: "/importantIndustryDetail",
        name: "ImportantIndustryDetail",
        title: "重点产业",
        hidden: true,
        component: () => import("@/views/Choice/ImportantIndustryDetail.vue"),
      },
    ],
  },
  {
    url: "/search",
    name: "Search",
    component: () => import("@/views/Search"),
    title: "全站搜索",
    hidden: true,
  },
];
router.beforeEach((to, from, next) => {
  if (!getRouter) {
    getMenu().then((res) => {
      // const currentMenuList = routerList.filter((v) => v.require);
      let isCurrent = false;
      getRouter = res.data;
      // res.data.forEach((item) => {
      //   const index = routerList.findIndex((v) => v.title === item.name);
      //   if (index !== -1) {
      //     currentMenuList.push(routerList[index]);
      //   }
      // });
      const currentMenuList = routerList;
      Store.commit("setMenuList", [
        {
          url: "/",
          name: "Home",
          title: "首页",
        },
        ...currentMenuList,
      ]);
      getRouterConfig(currentMenuList).forEach((route) => {
        if (route.path.indexOf(to.path) !== -1) {
          isCurrent = true;
        }
        router.addRoute(route);
      });
      router.addRoute({ path: "*", redirect: "/404", hidden: true });
      if (isCurrent) {
        setTimeout(() => {
          router.replace({
            path: to.fullPath,
          });
        });
      }
      next();
    });
  } else {
    next();
  }
});

function getRouterConfig(menus, parent) {
  let menusList = [];
  menus.map((menu) => {
    const routerConfig = {
      path: parent ? parent.path + menu.url : menu.url,
      component: menu.component || loadComponent(menu.name),
      name: menu.name,
      meta: {
        title: parent ? [...parent.meta.title, menu.title] : [menu.title],
      },
      redirect: menu.redirect,
    };
    menusList.push(routerConfig);
    if (menu.children && menu.children.length) {
      menusList = [
        ...menusList,
        ...getRouterConfig(menu.children, routerConfig),
      ];
    }
    return routerConfig;
  });
  return menusList;
}

const loadComponent = (view) => {
  if (!view) {
    return;
  }
  if (view.substring(0, 1) === "/") {
    view = view.substring(1);
  }
  if (process.env.NODE_ENV === "development1") {
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}`);
  }
};
