import request from "@/utils/request";

// 头部菜单
export function getMenu(params) {
  return request({
    url: "/exhibition-common-client/h5/topNavList",
    methods: "GET",
    params,
  });
}
// 公用banner接口
export function getBanner(params) {
  return request({
    url: "/exhibition-common-client/home/banners",
    methods: "GET",
    params,
  });
}

// 展会基本信息
export function getBaseInfo() {
  return request({
    url: "/exhibition-common-client/h5/baseInfo",
    methods: "GET",
  });
}

// 首页 - 获取功能栏列表
export function getFunctionList() {
  return request({
    url: "/exhibition-common-client/h5/functionBarList",
    methods: "GET",
  });
}

// 首页 - 推荐模块接口（推荐会议、展商、展品、视频、资讯、项目、直播）
export function getAllRecommend() {
  return request({
    url: "/exhibition-common-client/home/recommend",
    methods: "GET",
    params: { type: 2 },
  });
}

// 首页 - 展区导览 - 展馆列表
export function getShowHallList(params) {
  return request({
    url: "/exhibition-common-client/hall/selectHall",
    methods: "GET",
    params,
  });
}

// 首页 - 展区导览 - 展馆详情
export function getShowHallDetail(params) {
  return request({
    url: "/exhibition-common-client/hall/info",
    methods: "GET",
    params,
  });
}

// 首页 - 展区导览 - 展区列表
export function getShowAreaList(params) {
  return request({
    url: "/exhibition-common-client/hall/selectArea",
    methods: "GET",
    params,
  });
}

// 首页 - 获取合作伙伴列表
export function getPartnersList() {
  return request({
    url: "/exhibition-common-client/h5/partnersList",
    methods: "GET",
  });
}

// 首页 - 获取公告（防疫公告）列表
export function getNoticeList(params) {
  return request({
    url: "/exhibition-common-client/h5/noticeList",
    methods: "GET",
    params,
  });
}

// 首页 - 获取公告（防疫公告）详情
export function getNoticeDetail(params) {
  return request({
    url: "/exhibition-common-client/h5/noticeGetById",
    methods: "GET",
    params,
  });
}

// 首页 - 展区手绘地图 - 展馆列表
export function getMapList() {
  return request({
    url: "/exhibition-common-client/home/homeMapHallList",
    methods: "GET",
  });
}

// 首页 - 公告列表
export function getNoticeInfoList(params) {
  return request({
    url: "/exhibition-common-client/home/noticeInfoList",
    methods: "GET",
    params,
  });
}
// 首页 - 防疫公告列表
export function getPreventionList(params) {
  return request({
    url: "/exhibition-common-client/home/preventionList",
    methods: "GET",
    params,
  });
}
