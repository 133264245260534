<template>
  <div class="back-top">
    <div class="right-box">
      <img src="../../assets/Global/tel.png" alt="" />
      <div class="right-text">联系我们</div>
      <div class="extend tel-extend">0592-5892666</div>
    </div>
    <div class="right-box">
      <img src="../../assets/Global/wechat.png" alt="" />
      <div class="right-text">官方微信</div>
      <div class="extend qrcode-extend">
        <img src="../../assets//Global/qrcode.png" alt="" />
      </div>
    </div>
    <div class="right-box" @click="backTop" v-if="scrollTop > 0">
      <img src="../../assets/Global/top.png" alt="" />
      <div class="right-text">返回顶部</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll); // 销毁滚轮滚动事件
  },
};
</script>

<style lang="less" scoped>
.back-top {
  position: fixed;
  right: 20px;
  // top: 600px;
  top: calc(50vh - (78px * 3 / 2));
  z-index: 999;
  background-color: #ffffff;
  width: 72px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  .right-box {
    width: 72px;
    height: 78px;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    &:hover .extend {
      display: block;
    }
    &:last-child {
      border-bottom: none;
    }
    img {
      width: 32px;
      height: 32px;
      margin-top: 12px;
    }
    .right-text {
      font-size: 12px;
      color: #222222;
      // margin-top: 4px;
      line-height: 18px;
    }
    .extend {
      display: none;
      position: absolute;
      top: -1px;
      right: 73px;
      border: 1px solid #ebebeb;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    }
    .tel-extend {
      height: 78px;
      background-color: #ffffff;
      color: #222222;
      line-height: 78px;
      text-align: center;
      width: calc(145px + 36px);
      font-size: 20px;
    }
    .qrcode-extend {
      width: 114px;
      line-height: 0;
      img {
        width: 114px;
        height: 140px;
        margin-top: 0px;
      }
    }
  }
}
</style>
