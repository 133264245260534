import axios from "axios";
import { Message } from "element-ui";
// axios.defaults.withCredentials = true;
const BASE_URL = process.env.VUE_APP_BASE_URL;

console.log(process.env, "环境变量");
axios.defaults.headers["platformId"] = 4;
// axios.defaults.headers["identificationId"] = 7;
axios.defaults.headers["exhibitionId"] = process.env.VUE_APP_EXHIBITION_ID;
const request = axios.create({
  baseURL: BASE_URL, // api的base_url
  timeout: 10000, // 请求超时时间
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
request.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.code === "200") {
      return res.data;
    }
    return Promise.reject();
  },
  (error) => {
    console.log("错误日志", error);
    if (error && error?.response?.status) {
      Message.error("请求异常");
    }
    return Promise.reject();
  }
);

export default request;
