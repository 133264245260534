import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

export const constantRouterMap = [
  {
    path: "/",
    component: () => import("@/views/Home"),
    name: "Home",
    meta: {
      title: "首页",
    },
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRouterMap,
});

export default router;
