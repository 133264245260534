import moment from "moment";

export function isArray(content) {
  return Object.prototype.toString.call(content).slice(8, -1) === "Array";
}

export function computerTime(date) {
  let now = moment().format("x"); //获取当前时间戳

  let future = moment(date).format("x"); //距离的时间

  let index = (future - now) / 1000 / (60 * 60 * 24);

  let day = parseInt(index); //天

  let h = parseInt((index - day) * 24); //时

  let m = parseInt((index - day - h / 24) * 24 * 60); //分

  let s = parseInt((index - day - h / 24 - m / 60 / 24) * 24 * 60 * 60); //秒

  // let str = day + "天" + h + "小时" + m + "分" + s + "秒";

  return { day, h, m, s };
}

export function getRangeNum(m, n) {
  const aNumber = (n + 1 - m) * Math.random() + m;
  return Math.floor(aNumber);
}
