<template>
  <div id="footer" class="footer-wrap">
    <div class="footer-up">
      <div class="footer-content flex-row-space-between">
        <div class="left">
          <div class="title">2023海峡两岸云上博览会</div>
          <div class="f-title">电话：0536-8097701</div>
          <div class="f-title">邮箱：wftbltb@wf.shandong.cn</div>
          <div class="f-title">潍坊市胜利东街99号</div>
        </div>
        <div class="middle">
          <div>主办单位：国务院台湾事务办公室、山东省人民政府</div>
          <div>承办单位：山东省台港澳办、山东省商务厅、 潍坊市人民政府</div>
          <div>
            协办单位：全国台企联、台湾工业总会、台湾商业总会、台湾工 <br />
            商协进会、台湾工商建研会、台湾区电机电子工业同业公会、台 <br />
            湾工商企业联合会、台湾海峡两岸经贸交流协会
          </div>
        </div>
        <div class="right flex-row">
          <!-- <div class="code-wrap">
            <div class="code-small-img" />
            <div class="code-name">小程序</div>
          </div> -->
          <div class="code-wrap">
            <div class="code-web-img" />
            <div class="code-name">微信公众号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="footer-down">
      <div class="footer-content flex-center">
        版权所有 © 2021第27届鲁台会经贸洽谈会筹委会 保留一切权利 鲁台会云展会
        <img src="../../assets/Global/emblem.png" alt="" class="emblem" />
        闽公网安备
        <a href="https://beian.miit.gov.cn/" target="_blank">
          闽ICP备2021011761号-1
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavList",
};
</script>

<style scoped lang="less">
.footer-wrap {
  width: 100%;
  min-width: 1350px;
  background: url("../../assets/Home/bg-footer.png") center 100%;
  background-color: #222222;
}
.footer-up {
  width: 100%;
  height: 270px;
}
.divider {
  width: 1350px;
  height: 1px;
  margin: auto;
  background: rgba(255, 255, 255, 0.06);
}
.footer-down {
  width: 100%;
  height: 60px;
  line-height: 60px;
  // background: #09432e;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  .footer-content {
    font-size: 14px;
  }
}
.footer-up .footer-content {
  height: 254px;
}
.footer-content {
  width: 1350px;
  margin: 0 auto;
  .left {
    color: #fff;
    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      /* or 150% */
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
    .f-title {
      font-size: 15px;
      margin-bottom: 12px;
      line-height: 24px;
    }
    & .f-title:last-child {
      margin-bottom: 0;
    }
  }
  .middle {
    font-size: 15px;
    line-height: 26px;
    color: #fff;
    div {
      margin-bottom: 10px;
    }
    & div:last-child {
      margin-bottom: 0;
    }
  }
  .right {
    & .code-wrap:first-child {
      // margin-right: 32px;
    }
    .code-wrap {
      width: 100px;
      text-align: center;
      .code-small-img,
      .code-web-img {
        width: 100px;
        height: 100px;
        margin-bottom: 12px;
      }
      .code-small-img {
        background: url("../../assets/Home/code-small.jpg") no-repeat;
        background-size: 100% 100%;
      }
      .code-web-img {
        background: url("../../assets/Home/code-web.png") no-repeat;
        background-size: 100% 100%;
      }
      .code-name {
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        line-height: 22px;
      }
    }
  }
  a {
    color: #ffffff;
    margin-left: 5px;
    text-decoration: none;
  }
}
.emblem {
  margin: 0 6px 0 12px;
  width: 20px;
}
</style>
