<template>
  <div id="app">
    <NavList />
    <div :style="{ minHeight: minHeight + 'px' }" style="position: relative">
      <router-view :key="$route.fullPath + new Date().toDateString()" />
      <BackTop />
    </div>
    <Footer />
  </div>
</template>

<script>
import NavList from "@/components/NavList";
import Footer from "@/components/Footer";
import BackTop from "@/components/BackTop";
export default {
  name: "App",
  components: {
    NavList,
    Footer,
    BackTop,
  },
  data() {
    return {
      minHeight: 0,
    };
  },
  methods: {
    onWindowResize() {
      const navList = document.getElementById("navList");
      const footer = document.getElementById("footer");
      const screen =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.minHeight = screen - navList.offsetHeight - footer.offsetHeight;
    },
  },
  mounted() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
};
</script>
<style lang="less">
.common-tab-wrap {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-top: 3px solid #d61f26 !important;
  }
  .el-tabs__item {
    border-top: 3px solid transparent !important;
    padding: 0px 34px !important;
  }

  .el-tabs--border-card {
    border: none;
    box-shadow: none;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    border: none;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 32px !important;
    padding-right: 32px;
  }
  .el-tabs--border-card > .el-tabs__header {
    border-bottom: none;
  }
  .el-tabs__content {
    padding: 30px 40px !important;
  }
}
img {
  transition: all 0.3s;
}
.transition-img {
  &:hover {
    transform: scale(1.05);
  }
}
// 适配图片尺寸与容器不一致，导致图片变形不符合需求
// 将图片设置为背景图
.transition-img2 {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    transform: scale(1.05);
  }
}
</style>
