<template>
  <div>
    <div id="navList">
      <div class="global-content nav-content flex-row-space-between">
        <div class="flex-row-v-center">
          <!-- <img
            class="logo"
            src="../../assets/Global/logo.png"
            @click="onLinkHome"
          /> -->
          <img class="logo" :src="baseInfo.logo" @click="onLinkHome" />
          <!-- <div class="timeout-wrap flex-col" v-if="timeInfo.day > 0">
            <span class="timeout-title">距2022厦门种业博览会还有</span>
            <div>
              <span class="time-bg">{{ timeInfo.day }}</span>
              <span class="time-margin">天</span>
              <span class="time-bg">{{ timeInfo.h }}</span>
              <span class="time-margin-left">时</span>
            </div>
          </div> -->
        </div>
        <div>
          <el-input
            style="width: 320px"
            placeholder="请输入内容"
            class="input-with-select"
            size="medium"
            v-model="searchName"
            @keyup.enter.native="onSearch"
          >
            <div
              class="flex-row-center"
              slot="suffix"
              style="height: 40px; margin-right: 16px; cursor: pointer"
              @click="onSearch"
            >
              <i class="el-icon-search"></i>
            </div>
          </el-input>
        </div>
      </div>
    </div>
    <div style="background: #d61f26">
      <div class="global-content nav-container">
        <el-menu
          :default-active="activeIndex"
          class="nav-menu-wrap"
          mode="horizontal"
          @select="handleSelect"
        >
          <template v-for="(menu, index) in menuList">
            <el-menu-item
              v-if="!menu.children || !menu.showChildren"
              :key="menu.name"
              :index="`${index}`"
            >
              {{ menu.title }}
            </el-menu-item>
            <el-submenu
              :index="`${index}`"
              :key="menu.name"
              v-if="menu.children && menu.showChildren"
            >
              <template slot="title">{{ menu.title }}</template>
              <el-menu-item
                v-for="(menuChildren, idx) in menu.children"
                :key="menuChildren.name"
                :index="`${index}-${idx}`"
                v-show="!menuChildren.hidden"
              >
                {{ menuChildren.title }}
              </el-menu-item>
            </el-submenu>
          </template>
          <!-- <el-submenu index="2">
            <template slot="title">我的工作台</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">我的工作台</template>
            <el-menu-item index="3-1">选项1</el-menu-item>
            <el-menu-item index="3-2">选项2</el-menu-item>
            <el-menu-item index="3-3">选项3</el-menu-item>
          </el-submenu> -->
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { getBaseInfo } from "@/api/Home";
import { computerTime } from "@/utils/utils";
import { mapState } from "vuex";
export default {
  name: "NavList",
  computed: {
    ...mapState(["baseInfo"]),
    menuList() {
      return this.$store.state.menuList.filter((m) => !m.hidden);
    },
    activeIndex() {
      let index = "0";
      this.menuList.some((v, i) => {
        if (v.name === this.$route.name) {
          index = `${i}`;
          return true;
        }
        if (v.children && this.getChildrenIndex(v.children) > -1) {
          let childrenIndex = this.getChildrenIndex(v.children);
          index = `${i}-${childrenIndex}`;
          return true;
        }
        return false;
      });
      return index;
    },
  },
  data() {
    return {
      timeInfo: {},
      searchName: "",
    };
  },
  methods: {
    onLinkHome() {
      window.location.href = "/";
      // this.$router.push({ path: "/" });
    },
    getIsChildren(children) {
      return children.some((v) => {
        if (v.name === this.$route.name) return true;
        if (v.children && this.getIsChildren(v.children)) return true;
      });
    },
    getChildrenIndex(children) {
      return children.findIndex((v) => {
        return v.name === this.$route.name;
      });
    },
    handleSelect(key) {
      let currentName = this.$route.name;
      let toName = "";
      if (key.indexOf("-") > -1) {
        const [index, idx] = key.split("-");
        toName = this.menuList[index].children[idx].name;
      } else {
        toName = this.menuList[key].name;
      }
      if (currentName === toName) return;
      this.$router.push({ name: toName });
    },
    async getBaseInfo() {
      const { data } = await getBaseInfo();
      this.$store.commit("setBaseInfo", data);
      this.baseInfo = data;
      this.timeInfo = computerTime(data.startDate);
      console.log("基本信息", data);
    },
    onSearch() {
      this.$router.push({ path: "/search", query: { name: this.searchName } });
    },
  },
  mounted() {
    this.getBaseInfo();
  },
};
</script>

<style scoped lang="less">
#navList {
  width: 100%;
  // height: 100px;
  min-width: 1290px;
  background: #fff;

  .nav-content {
    height: 120px;
  }

  .logo {
    width: 426px;
    height: 64px;
    //background: url("https://exhibition-saas.oss-cn-hangzhou.aliyuncs.com/yunzhanhui/1/client/web/bg-logo.png")
    //  no-repeat;
    //background-size: 100% 100%;
    margin-right: 33px;
    cursor: pointer;
  }

  .time-bg {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: #17ad62;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .timeout-wrap {
    font-weight: 400;
    font-size: 14px;

    .timeout-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
    }

    .time-margin {
      margin: 0 4px;
    }

    .time-margin-left {
      margin-left: 4px;
    }
  }

  .nav-menu-wrap {
    //height: 30px;
  }
}
</style>
<style lang="less">
.nav-menu-wrap {
  background-color: transparent !important;
  &.el-menu.el-menu--horizontal {
    border-bottom: none;

    .el-menu-item {
      width: 150px;
      text-align: center;
      padding: 0;
      // margin: 0 24px;
      font-size: 16px;
    }
  }
  &.nav-menu-wrap.el-menu--horizontal > .el-submenu .el-submenu__title {
    width: 150px !important;
    text-align: center !important;
  }

  &.el-menu--horizontal > .el-menu-item {
    height: 50px;
    line-height: 50px;
    color: #ffffff;
    border-bottom: none;

    &:hover {
      color: #ffffff;
    }

    &.is-active {
      font-weight: 500;
      color: #ffffff;
      border-color: #ffffff;
      border-bottom: none;
      background-color: #c11c22 !important;
    }
  }

  &.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  &.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #ffffff !important;
    background-color: #c11c22 !important;
  }
  &.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    color: #ffffff !important;
    background-color: #c11c22 !important;
    border-bottom: 0;
  }

  &.el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 50px !important;
    line-height: 50px;
    border-bottom-width: 0px;
    color: #ffffff;
    font-size: 16px;
  }
  &.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: #c11c22 !important;
    color: #ffffff;
  }
  &.el-menu--horizontal > .el-submenu .el-submenu__title i {
    color: #ffffff !important;
  }
  &.el-menu--horizontal > .el-submenu:focus .el-submenu__title,
  &.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
    color: #ffffff !important;
  }
}
.nav-container {
  height: 50px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  color: #d61f26 !important;
  font-weight: 500;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #d61f26 !important;
  font-weight: 500;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #d61f26 !important;
  font-weight: 500;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #000 !important;
  font-weight: 400;
}
</style>
