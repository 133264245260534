import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/reset.less";
import "./styles/common.less";
import "./styles/flex.css";
import ElementUI from "element-ui";
import "./styles/element-variables.scss";
import "./permission";
// import "element-ui/lib/theme-chalk/index.css";
// import "./utils/element";
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
